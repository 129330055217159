<script>
  export let phoneNumber;
  import "global.css";
  import Header from "./Header.svelte";
  import Hero from "./Hero.svelte";
  import Recommendations from "./Recommendations.svelte";
  import Industry from "./Industry.svelte";
  import Cta from "./Cta.svelte";
</script>

<Header {phoneNumber} />
<Hero {phoneNumber} />
<Recommendations />
<Industry />
<Cta {phoneNumber}/>

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link
  href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:ital,wght@0,600;0,800;1,400&family=Roboto:wght@400;700&display=swap"
  rel="stylesheet"
/>