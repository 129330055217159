<script>
  let homeIcon = "./images/home-icon.svg";
  let homeImprove = "./images/homeimprove-logo.svg";
  export let phoneNumber;
</script>

<head>
  <div class="logo">
    <img src={homeIcon} alt="Home Improve Icon" />
    <img src={homeImprove} alt="homeImprove Logo" />
  </div>
  <div class="header-cta">
    <p>Experts are standing by</p>
    <a href="tel:{phoneNumber.replace(/-/g, '')}">{phoneNumber}</a>
  </div>
</head>

<style lang="scss">
  head {
    display: flex;
    justify-content: space-around;
    min-height: 100px;
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
      img:first-child {
        width: 69px;
        height: 69px;
        margin-right: 20px;
      }
      img:last-child {
        width: 211px;
        height: 30px;
      }
    }
    .header-cta {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      text-align: center;
      p {
        margin: 0;
        font-size: 1rem;
        line-height: 1.5;
        font-size: clamp(1rem, 0.9875rem + 0.0625vw, 1.0625rem);
        white-space: nowrap;
        color: var(--header-text);
      }
      a {
        font-size: 23px;
        line-height: 19.92px;
        color: var(--header-text);
      }
    }
  }

  @media (max-width: 768px) {
    head {
      flex-direction: column;
      margin: 20px 0;
      .header-cta {
        p {
          margin-top: 15px;
        }
      }
    }
  }
</style>
