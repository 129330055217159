<script>
  export let phoneNumber;
</script>

<div class="hero">
  <div class="container">
    <h1>How do you want to save?</h1>
    <h2>
      Get free quotes and connect with elite pros near you.
      <span class="teal">Free & Easy. </span>
    </h2>
    <button
      ><a href="tel:{phoneNumber.replace(/-/g, '')}"
        >Click to Call {phoneNumber}</a
      ></button
    >
  </div>
</div>

<style lang="scss">
  .hero {
    position: relative;
    overflow: auto;
    min-height: 471px;
    width: 100%;
    background: var(--hero-desktop);
    background-position: 0% 75%;

    .container {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 3%;
      max-width: 45vw;
      h1 {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 50px;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 15px 0;
      }
      h2,
      span {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: #ffffff;
        font-size: calc(1rem + 1vw);
        max-width: 40vw;
        margin: 15px 0 0;
      }
      .teal {
        color: var(--main-color);
        margin: 0 0 28px 0;
      }
      button {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: calc(1rem + 1vw);
        background: #ffffff;
        width: fit-content;
        height: 55px;
        color: var(--main-color);
        white-space: nowrap;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 20px;
        border: none;
        padding: 10px;
        border-radius: 27.5px;
        margin: 35px 0;
        padding: 0 5vw;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        }
        a {
          text-decoration: none;
          color: var(--main-color);
        }
      }
    }
  }
  @media (max-width: 800px) {
    .hero {
      background-position: 70%;
      background: var(--hero-mobile);
      .container {
        max-width: 100vw;
        h1 {
          font-size: 40px;
        }
        h2 {
          max-width: 70vw;
        }
        .teal {
          text-shadow: 0px 1px 1px rgb(255 255 255 / 23%);
        }
      }
    }
  }
</style>
