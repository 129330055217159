<div class="background">
  <h2>
    We can connect you with industry experts to save you the most money possible
  </h2>
</div>

<style lang="scss">
  .background {
    background: url("/images/industry.jpg") no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 216px;
    h2 {
      color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      font-size: 35px;
      font-weight: 600;
      text-align: center;
      max-width: 66vw;
    }
  }
</style>
