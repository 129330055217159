<script>
  let recs = [
    {
      title: "Solar Panels",
      src: "/images/solar.jpg",
    },
    {
      title: "Windows",
      src: "/images/windows.jpg",
    },
    {
      title: "Roofing",
      src: "/images/roofing.jpg",
    },
  ];
</script>

<div class="container">
  <h3>Recommended for You</h3>
  <div class="recommendations">
    {#each recs as { title, src }, i}
      <div class="recommendation">
        <img {src} alt={title} />
        <h4>{title}</h4>
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  .container {
    margin: 0 5%;
    h3 {
      font-family: "Poppins", sans-serif;
      font-size: calc(1rem + 1vw);
    }
    .recommendations {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      justify-content: space-evenly;
      margin: 2rem;

      .recommendation {
        img {
          width: 100%;
          max-width: 262px;
        }
        h4 {
          font-family: "Montserrat", sans-serif;
          font-weight: 600;
          font-size: 20px;
          text-align: left;
          margin: 14px 0;
        }
      }
    }
  }
  @media (max-width: 800px) {
    .container {
      h3 {
        text-align: center;
      }
      .recommendations {
        .recommendation {
          h4 {
            margin: 0 0 24px 0;
          }
        }
      }
    }
  }
</style>
