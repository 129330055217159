<script>
  export let phoneNumber;
</script>

<div class="container">
  <h2>Looking to improve & upgrade your home?</h2>
  <p>
    We have compared the nation's highest rated service providers. We are here
    to help you save and make it easy.
  </p>
  <button
    ><a href="tel:{phoneNumber.replace(/-/g, '')}"
      >Call us at <span class="phoneNumber">{phoneNumber || ""}</span></a
    ></button
  >
</div>

<style lang="scss">
  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 0 5%;
    min-height: 35vh;
    padding: 3% 0;
    h2 {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      font-size: 30px;
      text-align: center;
    }
    p {
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      margin: 10px 0;
      max-width: 75vw;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Poppins", sans-serif;
      font-weight: normal;
      font-size: 10px;
      background: var(--main-color);
      width: 100%;
      max-width: 418px;
      height: 55px;
      color: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 20px;
      border: none;
      padding: 10px;
      border-radius: 27.5px;
      margin: 40px 0 15px 0;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #ffffff;
        span.phoneNumber {
          font-weight: 800;
          font-size: 30px;
          margin-left: 10px;
        }
      }
    }
  }
</style>
